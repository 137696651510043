import React from "react";

function SettingsIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1998 14.9L20.9998 14.2C19.6998 13.4 19.6998 11.5 20.9998 10.7L22.1998 10C23.1998 9.4 23.4998 8.2 22.8998 7.3L21.8998 5.6C21.2998 4.6 20.0998 4.3 19.1998 4.9L17.9998 5.6C16.6998 6.4 14.9998 5.4 14.9998 3.9V2.5C14.9998 1.4 14.0998 0.5 12.9998 0.5H10.9998C9.89984 0.5 8.99984 1.4 8.99984 2.5V3.8C8.99984 5.3 7.29984 6.3 5.99984 5.5L4.79984 4.9C3.79984 4.3 2.59984 4.7 2.09984 5.6L1.09984 7.3C0.599845 8.3 0.899845 9.5 1.79984 10.1L2.99984 10.8C4.29984 11.5 4.29984 13.5 2.99984 14.2L1.79984 14.9C0.799845 15.5 0.499845 16.7 1.09984 17.6L2.09984 19.3C2.69984 20.3 3.89984 20.6 4.79984 20L5.99984 19.4C7.29984 18.6 8.99984 19.6 8.99984 21.1V22.5C8.99984 23.6 9.89984 24.5 10.9998 24.5H12.9998C14.0998 24.5 14.9998 23.6 14.9998 22.5V21.2C14.9998 19.7 16.6998 18.7 17.9998 19.5L19.1998 20.2C20.1998 20.8 21.3998 20.4 21.8998 19.5L22.8998 17.8C23.3998 16.7 23.0998 15.5 22.1998 14.9ZM11.9998 16.5C9.79984 16.5 7.99984 14.7 7.99984 12.5C7.99984 10.3 9.79984 8.5 11.9998 8.5C14.1998 8.5 15.9998 10.3 15.9998 12.5C15.9998 14.7 14.1998 16.5 11.9998 16.5Z"
        fill="#333333"
      />
    </svg>
  );
}

export default SettingsIcon;
